import React from "react"
import { graphql } from "gatsby"

// components
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import ReferenzenList from "../../../components/ImmobilienListReferenzen"
import ReferenzenListStockwerk from "../../../components/ImmobilienListReferenzenStockwerk"

const CurrentProjectsPage = ({ data }) => {
  return (
    <Layout logoImmobilienSelected={true}>
      <Seo title="Gips. Stuck. Trockenbau. Unsere Leistungen." />

      <section className="container px-4 pt-40 pb-12 mx-auto lg:px-24 xl:px-32">
        <div>
          <h1 className="pb-12 text-center h1">
            <span className="text-gray-800">
              Highlights aus unserer Entwicklung
            </span>
            :
          </h1>
        </div>
      </section>

      {data.EntwicklungPortfolio.totalCount >= 1 && (
        <section className="container px-4 py-4 mx-auto md:py-12 lg:py-24 lg:px-24 xl:px-32">
          <h2 className="mb-6 text-2xl h1">
            Entwicklung für das eigene Portfolio
          </h2>
          <ReferenzenList />
        </section>
      )}

      {data.Stockwerkeigentum.totalCount >= 1 && (
        <section className="bg-gray-200">
          <div className="container px-4 py-4 mx-auto md:py-12 lg:py-24 lg:px-24 xl:px-32">
            <h2 className="mb-6 text-2xl h1">Stockwerkeigentum</h2>
            <ReferenzenListStockwerk/>
          </div>
        </section>
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    EntwicklungPortfolio: allMdx( filter: { frontmatter: { referenzart: { eq: "Entwicklung für das eigene Portfolio" } } } ) {
      totalCount
    }
    Stockwerkeigentum: allMdx( filter: { frontmatter: { referenzart: { eq: "Stockwerkeigentum" } } } ) {
      totalCount
    }
  }
`

export default CurrentProjectsPage
